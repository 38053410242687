import { Button, Divider, Form, Modal, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
    CaretDownOutlined,
    CaretUpOutlined,
    LoadingOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons';
import apiRequest from '../../utils/api';
import { useSelector } from 'react-redux';
import { getCurrencySymbol, toFixedTrunc, useWindowWidth } from '../../utils/common';
import TransactionResultModal from './TransactionResultModal';
import { FormSelectCurrency } from '../forms';
import ThemeButton from '../common/ThemeButton';
import { fiatCurrencies } from '../../utils/constant';

const FXFiatModal = ({ open, handleClose, onSubmit, selectedCurrency, client }) => {
    const [form] = Form.useForm();
    const windowWidth = useWindowWidth();
    const clientFeesetting = useSelector(state => state.account.clientFeeSetting) || [];
    const balancesData = useSelector(state => state.account.balancesData) || [];
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const clientFXFee = clientFeesetting.find(e => e.transactionType === 'FX');
    const email = localStorage.getItem('email');
    const adminRole = localStorage.getItem('adminRole');

    const [step, setStep] = useState(1);
    const [fromAmount, setFromAmount] = useState('');
    const [toAmount, setToAmount] = useState('');
    const [fromCurrency, setFromCurrency] = useState(
        selectedCurrency ? selectedCurrency.currencyShortName : '',
    );
    const [toCurrency, setToCurrency] = useState('');
    const [rate, setRate] = useState(null);
    const [fxFee, setFxFee] = useState(false);
    const [fxRate, setFxRate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [unfreezeBalances, setUnfreezeBalances] = useState([]);
    useEffect(() => {
        const filteredBalances = balancesData.filter(balance => !balance.isFreeze);
        setUnfreezeBalances(filteredBalances);
    }, [balancesData]);
    useEffect(() => {
        if (open) {
            form.resetFields();
        }
        if (selectedCurrency) {
            setFromCurrency(selectedCurrency.currencyShortName);
            setToCurrency(unfreezeBalances[1]?.currencyShortName);
            // setToCurrency(balancesData[1].currencyShortName);
            form.setFieldsValue({
                fromCurrency: selectedCurrency.currencyShortName,
            });
            form.setFieldsValue({
                toCurrency: unfreezeBalances[1]?.currencyShortName,
            });
        } else {
            if (step === 1) {
                setFromCurrency(unfreezeBalances[0]?.currencyShortName);
                setToCurrency(unfreezeBalances[1]?.currencyShortName);
                form.setFieldsValue({
                    fromCurrency: unfreezeBalances[0]?.currencyShortName,
                });
                form.setFieldsValue({
                    toCurrency: unfreezeBalances[1]?.currencyShortName,
                });
            }
        }
    }, [unfreezeBalances]);

    useEffect(() => {
        const updateExchangeRate = async () => {
            if (fromCurrency && toCurrency) {
                try {
                    const fxRate = await fetchExchangeRate(fromCurrency, toCurrency);
                    if (fromAmount) {
                        const newToAmount = (parseFloat(fromAmount) * fxRate).toFixed(2);
                        setToAmount(newToAmount);
                        form.setFieldsValue({ to: newToAmount });
                    } else if (toAmount) {
                        const newFromAmount = (parseFloat(toAmount) / fxRate).toFixed(2);
                        setFromAmount(newFromAmount);
                        form.setFieldsValue({ from: newFromAmount });
                    }
                } catch (error) {
                    console.error('Error updating exchange rate:', error);
                }
            }
        };

        updateExchangeRate();
    }, [fromCurrency, toCurrency, clientFeesetting]);

    useEffect(() => {
        form.setFieldsValue({
            fromCurrency,
            toCurrency,
        });
    }, [fromCurrency, toCurrency]);

    const getFeeForCurrency = useCallback(
        (from, to) => {
            const clientFXFee = clientFeesetting.find(e => e.currency === from);
            if (clientFXFee?.fees) {
                return clientFXFee.fees.find(fee => fee?.toCurrency === to);
            }
            return null;
        },
        [clientFeesetting],
    );

    const fetchExchangeRate = async (from, to) => {
        setLoading(true);
        try {
            const response = await apiRequest('/get-fiat-exchange-rate', 'POST', {
                fromCurrency: from,
                toCurrency: to,
                adminRole: adminRole,
                email,
            });
            const fxRate = response.data?.fixedRate;
            const toCurrencyFee = getFeeForCurrency(from, to);
            const transactionFee = toCurrencyFee
                ? (Number(fxRate) * toCurrencyFee?.percentFee) / 100
                : 0;
            const rate = fxRate - transactionFee;
            setFxFee(transactionFee);
            setRate(rate);
            setFxRate(fxRate);
            setLoading(false);
            return fxRate;
        } catch (error) {
            setLoading(false);
            console.error('Error fetching exchange rate:', error);
            throw error;
        }
    };

    const createTransaction = async () => {
        try {
            const deductedAmount = fxFee && (Number(toAmount) * fxFee) / rate;
            const body = {
                adminRole: 'PaymentProviderAdmin',
                email,
                client: client,
                type: 'FX',
                currencyType: 'fiat',
                transactionDetails: {
                    fromCurrency: fromCurrency,
                    toCurrency: toCurrency,
                    fromAmount: fromAmount,
                    toAmount: toAmount,
                    fxrate: rate,
                    fxFee: fxFee,
                    exchangeRate: fxRate,
                },
                transactionFee: deductedAmount.toFixed(2),
                balance: {
                    updated: new Date(),
                },
            };
            setLoading(true);
            const response = await onSubmit(body);
            setStep(3);
            if (!response.success) {
                setError(response?.error);
            }
            handleClose();
            setLoading(false);
        } catch (error) {
            console.error('Error while creating transaction:', error);
        }
    };

    const handleFromAmountChange = e => {
        if (rate) {
            const value = e.target.value;
            setFromAmount(value);
            const calculatedToAmount = (value * rate).toFixed(2);
            setToAmount(calculatedToAmount);
            form.setFieldsValue({ to: calculatedToAmount });
            form.setFieldsValue({ from: value });
        }
    };

    const handleToAmountChange = e => {
        if (rate) {
            const value = e.target.value;
            const calculatedFromAmount = (value / rate).toFixed(2);
            setFromAmount(calculatedFromAmount);
            setToAmount(value);
            form.setFieldsValue({ from: calculatedFromAmount });
            form.setFieldsValue({ to: value });
        }
    };

    const handleFromCurrencyChange = value => {
        setFromCurrency(value);
        if (value === toCurrency) {
            setToCurrency('');
            form.resetFields(['to']);
            form.resetFields(['toCurrency']);
        }
        setFromAmount('');
        setToAmount('');
        form.setFieldsValue({ from: '' });
        form.setFieldsValue({ to: '' });
    };

    const handleToCurrencyChange = value => {
        setToCurrency(value);
        if (value === fromCurrency) {
            setFromCurrency('');
            form.resetFields(['from']);
            form.resetFields(['fromCurrency']);
        }
        setFromAmount('');
        setToAmount('');
        form.setFieldsValue({ from: '' });
        form.setFieldsValue({ to: '' });
    };

    const getCurrencyIconUrl = currency => {
        const currencyData = balancesData.find(data => data.currencyShortName === currency);
        return currencyData ? `${imageBaseUrl}${currencyData.currencyIcon}` : '';
    };

    const getCurrencyFullName = currency => {
        const currencyData = balancesData.find(data => data.currencyShortName === currency);
        return currencyData ? currencyData.currencyFullName : '';
    };

    const onCancel = () => {
        setToAmount('');
        setToCurrency('');
        setFromAmount('');
        setFromCurrency(
            selectedCurrency
                ? selectedCurrency.currencyShortName
                : unfreezeBalances[0].currencyShortName,
        );
        setToCurrency(unfreezeBalances[1].currencyShortName);
        setRate(null);
        setFxFee(null);
        setFxRate(null);
        setStep(1);
        form.resetFields();
        setError('');
        handleClose();
    };

    useEffect(() => {
        if (open && selectedCurrency) {
            setFromCurrency(selectedCurrency.currencyShortName);
        }
    }, [selectedCurrency, open]);

    return (
        <>
            <Modal
                className="transaction-modal deposit-modal"
                width={570}
                centered
                title={
                    step === 1 ? (
                        'FX'
                    ) : step === 2 ? (
                        <div className="flex items-center">
                            <ArrowLeftOutlined
                                className="text-xl mr-[12px] w-6 h-6"
                                onClick={() => setStep(1)}
                            />
                            <p className="text-2xl font-semibold relative flex">FX Confirmation</p>
                        </div>
                    ) : null
                }
                open={open}
                onCancel={() => {
                    handleClose();
                    onCancel();
                }}
                handleClose={() => {
                    handleClose();
                    onCancel();
                }}
                footer={
                    step === 3 ? null : (
                        <div className="flex justify-end w-full">
                            {step === 2 && windowWidth > 639 && (
                                <div className="and-btn-min-width mr-3">
                                    <ThemeButton
                                        shape="round"
                                        key="cancel"
                                        action={() => {
                                            handleClose();
                                            onCancel();
                                        }}
                                        text="Cancel"
                                    />
                                </div>
                            )}
                            <div className="and-btn-min-width">
                                <ThemeButton
                                    type="primary"
                                    shape="round"
                                    loading={step === 2 && loading}
                                    disabled={!rate}
                                    action={async () => {
                                        try {
                                            await form.validateFields(); // Validate form fields
                                            if (step === 1) {
                                                setStep(2);
                                            } else {
                                                createTransaction();
                                            }
                                        } catch (error) {
                                            console.error('Validation failed:', error);
                                        }
                                    }}
                                    text={
                                        step === 1
                                            ? 'Continue'
                                            : `${loading ? 'Processing' : 'Exchange'}`
                                    }
                                    dataE2e={
                                        step === 1
                                            ? 'Continue'
                                            : `${loading ? 'Processing' : 'Exchange'}`
                                    }
                                />
                            </div>
                        </div>
                    )
                }>
                {step === 1 ? (
                    <Form
                        form={form}
                        labelCol={{ style: { fontSize: '16px' } }}
                        colon={false}
                        layout="horizontal"
                        className="p-2 max-h-[80vh] modal-form overflow-y-auto transaction-form-FX">
                        <FormSelectCurrency
                            label="From:"
                            name="from"
                            required
                            rules={[
                                { required: true, message: 'Please enter the amount' },
                                {
                                    validator: async (_, value) => {
                                        const numValue = Number(value);
                                        if (isNaN(numValue) || numValue <= 0) {
                                            return Promise.reject(
                                                new Error('The amount must be greater than 0'),
                                            );
                                        }
                                        if (!rate) {
                                            return Promise.reject(
                                                new Error('Failed to fetch exchange rate.'),
                                            );
                                        }
                                        const balance = balancesData.find(
                                            currency => currency.currencyShortName === fromCurrency,
                                        );
                                        if (balance?.balanceAmount < numValue) {
                                            return Promise.reject(
                                                new Error(
                                                    'Please adjust the value as you have an insufficient balance',
                                                ),
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                            tooltip={
                                !toCurrency || !fromCurrency
                                    ? 'Please select the currencies first'
                                    : ''
                            }
                            type="number"
                            disabled={!toCurrency || !fromCurrency}
                            placeholder={
                                !toCurrency || !fromCurrency
                                    ? 'Select currencies first'
                                    : 'Enter from amount'
                            }
                            onChange={e => {
                                const value = e.target.value;
                                handleFromAmountChange({ target: { value } });
                            }}
                            defaultValue={fromAmount}
                            selectDataE2e="from-drop-down"
                            selectName="fromCurrency"
                            selectDefaultValue={fromCurrency}
                            options={unfreezeBalances.map(data => ({
                                value: data.currencyShortName,
                                label: (
                                    <div className="flex items-center">
                                        <img
                                            src={`${imageBaseUrl}${data.currencyIcon}`}
                                            alt={data.currencyShortName}
                                            data-e2e={data.currencyShortName}
                                            className="w-5 mr-[10px]"
                                        />
                                        {data.currencyShortName}
                                    </div>
                                ),
                            }))}
                            onSelectChange={handleFromCurrencyChange}
                        />

                        <div className="my-5 flex items-center">
                            <div className="flex-1 border-b border-[#E4E4E7]"></div>
                            <div className="w-10 h-10 flex-shrink-0 rounded-full flex border border-[#D1D1D6] flex-col justify-center items-center">
                                <CaretUpOutlined className="-mb-0.5" />
                                <CaretDownOutlined className="-mt-0.5" />
                            </div>
                        </div>

                        <FormSelectCurrency
                            label="To:"
                            name="to"
                            selectDataE2e="to-drop-down"
                            required
                            rules={[{ required: true, message: 'Please enter the amount' }]}
                            tooltip={
                                !toCurrency || !fromCurrency
                                    ? 'Please select the currencies first'
                                    : ''
                            }
                            type="number"
                            disabled={!toCurrency || !fromCurrency}
                            placeholder={
                                !toCurrency || !fromCurrency
                                    ? 'Select currencies first'
                                    : 'Enter to amount'
                            }
                            onChange={e => {
                                const value = e.target.value;
                                handleToAmountChange({ target: { value } });
                            }}
                            defaultValue={toAmount}
                            selectName="toCurrency"
                            selectDefaultValue={toCurrency}
                            options={(unfreezeBalances || []).map(data => ({
                                value: data.currencyShortName,
                                label: (
                                    <div className="flex items-center">
                                        <img
                                            src={`${imageBaseUrl}${data.currencyIcon}`}
                                            alt={data.currencyShortName}
                                            data-e2e={data.currencyShortName}
                                            className="w-5 mr-[10px]"
                                        />
                                        {data.currencyShortName}
                                    </div>
                                ),
                            }))}
                            onSelectChange={handleToCurrencyChange}
                        />

                        <div className="mt-[30px] flex justify-between items-center">
                            <p className="flex-1 text-[#26272B] text-base">Rate:</p>
                            <div className="flex gap-2 items-center">
                                {loading && (
                                    <Spin indicator={<LoadingOutlined spin />} size="small" />
                                )}
                                {fromCurrency && toCurrency && (
                                    <p className="text-base font-medium text-[#26272B]">{`1 ${fromCurrency} = ${toFixedTrunc(rate, 4)} ${toCurrency}`}</p>
                                )}
                            </div>
                        </div>
                        <Divider className="my-6" />
                        <div className="mt-[30px] flex justify-between items-center">
                            <p className="flex-1 text-[#26272B] text-base">Total:</p>
                            <p className="text-base font-medium text-[#26272B]">
                                {`${getCurrencySymbol(toCurrency)}  ${toFixedTrunc(toAmount)} ${toCurrency && `(${toCurrency})`}`}
                            </p>
                        </div>
                    </Form>
                ) : step === 2 ? (
                    <div className="my-12">
                        <div className="sm:border border-[#D1D1D6] rounded-md">
                            <div className="p-3 pb-0">
                                <p className="text-base text-[#3F3F46] mb-5">
                                    Transaction information:
                                </p>
                                <p className="text-[#51525C] mb-2">From:</p>
                                <div className="flex sm:justify-between gap-2.5 sm:gap-0 items-center mb-4">
                                    <span className="flex items-center  space-x-2">
                                        <img
                                            src={getCurrencyIconUrl(fromCurrency)}
                                            className="w-7 h-7 rounded-2xl"
                                            alt={fromCurrency}
                                        />
                                        <p className="text-xl text-[#26272B] hidden sm:block">
                                            {getCurrencyFullName(fromCurrency)}
                                        </p>
                                    </span>
                                    <p className="font-semibold text-xl text-[#26272B]">
                                        {`${getCurrencySymbol(fromCurrency)} ${toFixedTrunc(fromAmount)} (${fromCurrency})`}
                                    </p>
                                </div>
                                <Divider className="my-3" />
                                <p className="text-[#51525C] mb-2">To:</p>
                                <div className="flex sm:justify-between gap-2.5 sm:gap-0 items-center mb-4">
                                    <span className="flex items-center space-x-2">
                                        <img
                                            src={getCurrencyIconUrl(toCurrency)}
                                            className="w-7 h-7 rounded-2xl"
                                            alt={toCurrency}
                                            data-e2e={toCurrency}
                                        />
                                        <p className="text-xl text-[#26272B] hidden sm:block">
                                            {' '}
                                            {getCurrencyFullName(toCurrency)}
                                        </p>
                                    </span>
                                    <p className="font-semibold text-xl text-[#26272B]">
                                        {`${getCurrencySymbol(toCurrency)} ${toFixedTrunc(toAmount)} (${toCurrency})`}
                                    </p>
                                </div>
                            </div>

                            <div className="mt-5 p-3 sm:bg-[#FAFAFA] border-t border-[#D1D1D6] sm:flex justify-between items-center">
                                <p className="text-base mb-3 sm:mb-0">Rate: </p>
                                <p className="text-primary text-xl font-semibold sm:font-medium sm:text-base">{`1 ${fromCurrency} = ${rate} ${toCurrency}`}</p>
                            </div>
                            <div className=" sm:hidden  mt-5 p-3 sm:bg-[#FAFAFA] border-t border-[#D1D1D6] ">
                                <p className="text-base mb-3 sm:mb-0">Total: </p>
                                <p className="text-primary text-xl font-semibold sm:font-medium sm:text-base">{`${getCurrencySymbol(toCurrency)}  ${toAmount} ${toCurrency && `(${toCurrency})`}`}</p>
                            </div>
                        </div>
                    </div>
                ) : null}
            </Modal>

            <TransactionResultModal
                type={error ? error : 'success'}
                open={step === 3}
                handleClick={onCancel}
                title={error ? 'Error!' : 'Successful!'}
                message={error ? error : 'Has been exchanged successfully!'}>
                {!error && (
                    <>
                        <p className="text-xl font-medium text-primary text-center">
                            {`${getCurrencySymbol(fromCurrency)} ${toFixedTrunc(fromAmount)} ${fromCurrency}`}
                        </p>
                        <p className="text-base text-[#3F3F46] text-center">
                            {` (${getCurrencySymbol(toCurrency)} ${toFixedTrunc(toAmount)} ${toCurrency})`}
                        </p>
                    </>
                )}
            </TransactionResultModal>
        </>
    );
};

export default FXFiatModal;
